import Appsignal from '@appsignal/javascript'
import { plugin as pluginConsole } from '@appsignal/plugin-breadcrumbs-console'
import { plugin as pluginNetwork } from '@appsignal/plugin-breadcrumbs-network'
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator'

const key = import.meta.env.VITE_APPSIGNAL_PUSH_API_KEY

const appSignal = new Appsignal({ key })

appSignal.use(pluginPathDecorator())
appSignal.use(pluginConsole())
appSignal.use(pluginNetwork())

export { appSignal }
